import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_GRAPHQL_ENDPOINT } from 'config';
import { getAuthToken } from 'providers/auth';

const httpLink = createHttpLink({
  uri: API_GRAPHQL_ENDPOINT,
  fetchOptions: { credentials: 'same-origin' },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      origin: window.location.href,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
