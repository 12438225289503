export const createDefaultStore = (initData = {}) => {
  const data = initData;

  const getItem = (key: string): string => {
    console.log(data['POS-STORAGE']);
    return data[key];
  };

  const setItem = (key: string, value: string) => {
    data[key] = value;
    localStorage.setItem(key, value);
  };

  const removeItem = (key: string) => {
    delete data[key];
    localStorage.removeItem(key);
  };

  return { getItem, setItem, removeItem };
};
