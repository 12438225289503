export enum Routes {
  // Common
  Integrations = '/integrations',
  IntegrationsList = '/integrations/list',
  IntegrationsAdd = '/integrations/add',
  Home = '/',
  // Misc
  Login = '/login',
  PageBadRequest = '/400',
  PageNotFound = '/404',
}
