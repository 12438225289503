import { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles, fade, createStyles } from '@material-ui/core/styles';
import {
  AppBar as Bar,
  Box,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  MenuItemProps,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from '@material-ui/icons/Add';
import { Routes } from 'enums';

export interface AdminAppBarQuickActionMenuItem {
  label: string;
  props: MenuItemProps;
}

export interface AdminAppBarProps {
  isDrawerOpen: boolean;
  onToggleDrawer: () => void;
  quickActionMenuItems?: AdminAppBarQuickActionMenuItem[];
  title?: string;
}

interface StyledTabProps {
  label: string;
  component?: any;
  to?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 300,
    width: `calc(100% - 300px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {},
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  closeIcon: {
    position: 'absolute',
  },
  search: {
    position: 'relative',
    borderRadius: 50,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    border: '1px solid #eee',
    marginRight: theme.spacing(1),
    width: '100%',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.025),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const AntTabs = withStyles({
  root: {
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 'auto',
      minHeight: 'auto',
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(3),
      padding: theme.spacing(0, 0, 1),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export const AppBar = ({
  title,
  isDrawerOpen,
  quickActionMenuItems,
  onToggleDrawer,
}: AdminAppBarProps) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // Helpers
  const hasQuickActions = quickActionMenuItems && quickActionMenuItems.length;
  const hasTitle = title !== undefined;

  const renderTabs = () => (
    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
      <AntTab label="Diarization" component={Link} to={''} />
      <AntTab label="Analyze" component={Link} to={''} />
    </AntTabs>
  );

  return (
    <Bar
      position="absolute"
      color="inherit"
      variant="outlined"
      className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggleDrawer}
          className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>

        <Avatar
          src="https://images.pexels.com/photos/4723517/pexels-photo-4723517.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          variant="rounded"
          style={{ marginLeft: '1rem', width: 48, height: 48, borderRadius: 12 }}
        />

        <Box style={{ marginLeft: '1rem', paddingTop: '0.5rem' }}>
          <Box display="flex" alignItems="center">
            {hasTitle && (
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                display="block"
              >
                {title}
              </Typography>
            )}
            <IconButton color="secondary" size="small" style={{ marginLeft: '1rem' }}>
              <InfoIcon />
            </IconButton>
            <IconButton color="secondary" size="small">
              <StarBorderIcon />
            </IconButton>
          </Box>
          {renderTabs()}
        </Box>

        <Box flex={1} />

        <div className={classes.search}>
          <Box display="flex" alignItems="center">
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {searchTerm && (
              <div className={classes.closeIcon} onClick={() => setSearchTerm('')}>
                <CloseIcon />
              </div>
            )}
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => console.log(e)}
            />
          </Box>
        </div>

        <IconButton style={{ marginRight: '0.5rem' }}>
          <HelpIcon />
        </IconButton>

        <Avatar alt="Remy Sharp" src="https://randomuser.me/api/portraits/women/91.jpg" />

        {hasQuickActions && (
          <Fragment>
            <IconButton
              color="inherit"
              aria-controls="AdminAppBarQuickActionMenu"
              aria-haspopup="true"
              onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            >
              <AddCircleIcon />
            </IconButton>
            <Menu
              keepMounted
              id="AdminAppBarQuickActionMenu"
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              onClick={() => setMenuAnchorEl(null)}
            >
              {quickActionMenuItems?.map(({ label, props }, key) => (
                <MenuItem
                  key={`AdminAppBarQuickActionMenuItem-${key}`}
                  {...(props as any)}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        )}
      </Toolbar>
    </Bar>
  );
};

export default AppBar;
