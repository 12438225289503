import { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import OrganizationIcon from '@material-ui/icons/BusinessOutlined';
import ReportsIcon from '@material-ui/icons/BarChart';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import { Routes } from 'enums';
import { AppBar, Drawer, DrawerPropsNavItems } from 'components';
import IntegrationsList from './IntegrationsList';
import IntegrationAdd from './IntegrationAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },

  appBarSpacer: theme.mixins.toolbar,

  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: '0.25s',
  },

  shifted: {
    transform: 'translateX(300px)',
  },

  items: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
}));

const IntegrationsRoot = () => {
  const styles = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  /** Nav Drawer Items */
  const drawerNavItems: DrawerPropsNavItems = [
    [
      { icon: <DashboardIcon color="inherit" />, label: 'Home', route: '' },
      { icon: <InboxIcon color="inherit" />, label: 'Inbox', route: '' },
      { icon: <ReportsIcon color="inherit" />, label: 'Analytics', route: 'null' as any },
      {
        icon: <OrganizationIcon color="inherit" />,
        label: 'Organization',
        route: 'null' as any,
      },
    ],
  ];

  const handleShowDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleHideDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className={styles.root}>
      <AppBar
        onToggleDrawer={handleShowDrawer}
        isDrawerOpen={isDrawerOpen}
        title="Welcome to Powow"
      />
      <Drawer open={isDrawerOpen} navItems={drawerNavItems} onClose={handleHideDrawer} />
      <div className={styles.content}>
        <div className={styles.appBarSpacer} />
        <Switch>
          <Route exact path={Routes.IntegrationsAdd} component={IntegrationAdd} />
          <Route exact path={Routes.IntegrationsList} component={IntegrationsList} />
          <Redirect exact from={Routes.Integrations} to={Routes.IntegrationsList} />
        </Switch>
      </div>
    </div>
  );
};

export default IntegrationsRoot;
