import { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, Divider, IconButton, Drawer as MaterialDrawer } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemNavLink from 'components/ListItemNavLink';

export interface DrawerPropsNavItem {
  icon: React.ReactElement;
  label: string;
  route: string;
}

export type DrawerPropsNavItems = DrawerPropsNavItem[][];

export interface DrawerProps {
  open: boolean;
  navItems: DrawerPropsNavItems;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 300,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.surface.main,
    color: theme.palette.primary.contrastText,
  },
  drawerPaperClose: {
    overflow: 'hidden',
    borderRight: 'transparent',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 1rem',
    ...theme.mixins.toolbar,
  },
}));

export const Drawer = ({ open, onClose, navItems }: DrawerProps) => {
  const classes = useStyles();

  const items = useMemo(
    () =>
      navItems.map((navGroup, groupKey) => (
        <div key={`DrawerList_${groupKey}`}>
          {navGroup.map((item, itemKey) => (
            <ListItemNavLink
              key={`DrawerListItemNavLink-${itemKey}`}
              to={item.route}
              primary={item.label}
              icon={item.icon}
            />
          ))}
        </div>
      )),
    [navItems],
  );

  return (
    <MaterialDrawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton color="inherit" onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {items.map((listItems, key) => (
        <Fragment key={`DrawerListGroup-${key}`}>
          <List>{listItems}</List>
          <Divider />
        </Fragment>
      ))}
    </MaterialDrawer>
  );
};

export default Drawer;
