import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Routes } from 'enums';
import { APP_NAME } from 'config';
import { showUserErrorsToast, getFormData } from 'utils';
import { login, useAuth } from 'providers/auth';
import { useTenantUserLoginMutation } from 'graphql/generated';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: theme.palette.grey[50],
    fontSize: '0.875rem',
  },

  container: {
    width: 'auto',
    borderRadius: '10px',
    boxShadow: theme.shadows[1],
    boxSizing: 'border-box',
    margin: '80px 8px 0',
    maxWidth: '440px',
    padding: '64px 48px 32px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '585px',
    },
  },

  divider: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    margin: theme.spacing(1, 0),
  },

  line: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    flex: '1 1 auto',
    marginTop: '8px',
    minWidth: '1px',
    paddingTop: '8px',
  },

  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }
}));

function getDestinationRoute(fallbackRoute: Routes): string {
  const searchParams = new URLSearchParams(window.location.search);
  const destinationRoute = searchParams.get('destination');
  return destinationRoute || fallbackRoute;
}

const TenantUserLogin = () => {
  const styles = useStyles();
  const [isLoggedIn] = useAuth();

  const isDemoMode = false;

  const [loginMutation, { loading }] = useTenantUserLoginMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const input = getFormData(event);
    const { data } = await loginMutation({ variables: { input } });

    if (!data) return;

    const { accessToken, userErrors } = data.tenantUserLogin;

    if (userErrors.length) {
      showUserErrorsToast(userErrors);
    } else if (accessToken) {
      login({ accessToken });
    }
  };

  if (isLoggedIn) return <Redirect to={getDestinationRoute(Routes.Home)} />;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Box mb={5}>
          <Typography variant="h4" align="center">
            {APP_NAME}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <fieldset disabled={loading}>
            <Box mb={3}>
              <TextField
                fullWidth
                size="small"
                type="email"
                name="email"
                label="Email address"
                variant="outlined"
                disabled={isDemoMode}
                required
              />
            </Box>

            <Box mb={3}>
              <TextField
                fullWidth
                size="small"
                type="password"
                name="password"
                label="Password"
                variant="outlined"
                disabled={isDemoMode}
                required
              />
            </Box>
          </fieldset>

          <Box mb={3} textAlign="center">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <span>Log in</span>}
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default TenantUserLogin;
