import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { getFormData } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 345,
  },
}));

const IntegrationAdd = () => {
  const styles = useStyles();

  const loading = false;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const input = getFormData(event);
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" disabled={loading}>
          <FormLabel component="legend">Set up integration</FormLabel>

          <Box mb={3}>
            <TextField
              fullWidth
              size="small"
              type="text"
              variant="outlined"
              name="name"
              label="Name"
              required
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              multiline
              size="small"
              type="text"
              variant="outlined"
              name="description"
              label="Description"
              rows={2}
            />
          </Box>

          <Box mb={3}>
            <FormControlLabel control={<Switch name="active" color="primary" />} label="Publish" />
          </Box>
        </FormControl>

        <Box mb={3} textAlign="center">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : <span>Add integration</span>}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default IntegrationAdd;
