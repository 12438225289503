export interface CreateTokenProvider<T> {
  accessTokenExpireKey?: string;
  accessTokenKey?: string;
  localStorageKey: string;
  onUpdateToken?: (token: T) => Promise<T | null>;
  onHydratation?: (token: T | null) => void;
  storage: {
    getItem: (key: string) => any;
    setItem: (key: string, value: any) => void;
    removeItem: (key: string) => void;
  };
}

export const createTokenProvider = <T>({
  accessTokenExpireKey,
  accessTokenKey,
  localStorageKey,
  onUpdateToken,
  onHydratation,
  storage,
}: CreateTokenProvider<T>) => {
  let listeners: ((newLogged: boolean) => void)[] = [];

  const getTokenInternal = (): T | null => {
    const data = storage.getItem(localStorageKey);
    const token = (data && JSON.parse(data)) || null;
    return token;
  };

  const subscribe = (listener: (logged: boolean) => void) => {
    listeners.push(listener);
  };

  const unsubscribe = (listener: (logged: boolean) => void) => {
    listeners = listeners.filter((entity) => entity !== listener);
  };

  const jwtExp = (token?: any): number | null => {
    if (!(typeof token === 'string')) return null;
    const tokenParts = token.split('.');
    if (tokenParts.length < 2) return null;

    try {
      const jwt = JSON.parse(atob(tokenParts[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const getExpire = (token: T | null) => {
    if (!token) return null;

    if (accessTokenExpireKey) {
      return token[accessTokenExpireKey];
    }

    if (accessTokenKey) {
      const exp = jwtExp(token[accessTokenKey]);
      if (exp) return exp;
    }

    return jwtExp(token);
  };

  const isExpired = (exp?: number): boolean => {
    if (!exp) return false;
    return Date.now() > exp;
  };

  const checkExpiry = async () => {
    const token = getTokenInternal();
    if (token && isExpired(getExpire(token))) {
      // request for new tokens, if applicable
      const newToken = onUpdateToken ? await onUpdateToken(token) : null;
      if (newToken) {
        setToken(newToken);
      } else {
        storage.removeItem(localStorageKey);
      }
    }
  };

  const getToken = async () => {
    await checkExpiry();

    if (accessTokenKey) {
      const token = getTokenInternal();
      return token && token[accessTokenKey];
    }

    return getTokenInternal();
  };

  const isLoggedIn = () => {
    const token = getTokenInternal();
    if (onHydratation) onHydratation(token);
    return !!token;
  };

  const setToken = (token: T | null) => {
    if (token) {
      storage.setItem(localStorageKey, JSON.stringify(token));
    } else {
      storage.removeItem(localStorageKey);
    }
    notify();
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    listeners.forEach((listener) => listener(isLogged));
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};
