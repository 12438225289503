export enum FlowObjectTypes {
  /**
   * Billing object.
   */
  BILLING = 'BILLING',
  /**
   *
   */
  CART = 'CART',
  /**
   *
   */
  CHECKOUT = 'CHECKOUT',
  /**
   *
   */
  COLLECTION = 'COLLECTION',
  /**
   *
   */
  CUSTOMER = 'CUSTOMER',
  /**
   *
   */
  DISPUTE = 'DISPUTE',
  /**
   * Manually create orders on behalf of customers. The order is saved as a
   * draft in a CMS admin until it's marked as completed.
   */
  DRAFT_ORDER = 'DRAFT_ORDER',
  /**
   *
   */
  FULFILLMENT = 'FULFILLMENT',
  /**
   *
   */
  FULFILLMENT_EVENT = 'FULFILLMENT_EVENT',
  /**
   *
   */
  INVENTORY_ITEM = 'INVENTORY_ITEM',
  /**
   *
   */
  LOCATION = 'LOCATION',
  /**
   * A store's orders. Each order is a record of a complete purchase that
   * includes details of the customer, their cart, and any transactions.
   */
  ORDER = 'ORDER',
  /**
   * Transactions for an order. Transactions are created for every order that
   * results in an exchange of money.
   */
  ORDER_TRANSACTION = 'ORDER_TRANSACTION',
  /**
   *
   */
  PRODUCT = 'PRODUCT',
  /**
   *
   */
  PRODUCT_LISTING = 'PRODUCT_LISTING',
  /**
   *
   */
  PROFILE = 'PROFILE',
  /**
   * Refunds for an order. Each refund is a record of money being returned to
   * the customer. Use the calculate endpoint to make sure that any refund that
   * you create is accurate.
   */
  REFUND = 'REFUND',
  /**
   *
   */
  SHOP = 'SHOP',
  /**
   *
   */
  SUBSCRIPTION_CONTRACT = 'SUBSCRIPTION_CONTRACT',
  /**
   *
   */
  TENDER_TRANSACTION = 'TENDER_TRANSACTION',
}
