import { useCallback, useState, useEffect } from 'react';
import { createDefaultStore } from './defaultStorage';
import { createTokenProvider } from './tokenProvider';

export interface CreateAuthProviderProps<T> {
  accessTokenExpireKey?: string;
  accessTokenKey?: string;
  localStorageKey?: string;
  onUpdateToken?: (token: T) => Promise<T | null>;
  onHydratation?: (token: T | null) => void;
  storage?: {
    getItem: (key: string) => any;
    setItem: (key: string, value: any) => void;
    removeItem: (key: string) => void;
  };
}

export const createAuthProvider = <T>({
  onUpdateToken,
  onHydratation,
  accessTokenExpireKey,
  accessTokenKey = 'accessToken',
  localStorageKey = 'POS-STORAGE',
  storage = createDefaultStore({ [localStorageKey]: localStorage.getItem(localStorageKey) }),
}: CreateAuthProviderProps<T>) => {
  const tokenProvider = createTokenProvider({
    onUpdateToken,
    onHydratation,
    storage,
    accessTokenExpireKey,
    accessTokenKey,
    localStorageKey,
  });

  const login = (newTokens: T) => {
    tokenProvider.setToken(newTokens);
  };

  const logout = () => {
    tokenProvider.setToken(null);
  };

  const getAuthToken = async () => {
    return await tokenProvider.getToken();
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
    const listener = useCallback((newIsLogged) => setIsLogged(newIsLogged), [setIsLogged]);

    useEffect(() => {
      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, [listener]);

    return [isLogged];
  };

  return { useAuth, login, logout, getAuthToken, storage };
};
