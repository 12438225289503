export enum FlowFrequencies {
  /**
   * The flow agent job is run only once.
   */
  ONCE = 'ONCE',
  /**
   * The flow agent job is run daily.
   */
  DAILY = 'DAILY',
  /**
   * The flow agent job is run weekly.
   */
  WEEKLY = 'WEEKLY',
  /**
   * The flow agent job is run monthly.
   */
  MONTHLY = 'MONTHLY',
  /**
   * The flow agent job is run yearly.
   */
  YEARLY = 'YEARLY',
}
