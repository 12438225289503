import { toast } from 'react-toastify';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import { Box, Typography } from '@material-ui/core';
import { capitalizeFirstLetter } from 'utils/strings';

export const showUserErrorsToast = (
  userErrors: { field: string[]; message: string }[],
): void => {
  toast.error(
    <div>
      {userErrors.map((entry, key) => (
        <Box key={`${entry.message}${key}`} my={2}>
          <Box display="flex" alignItems="center" mb={1}>
            <Box mr={1}>
              <WarningIcon />
            </Box>
            <Typography variant="body1">
              {capitalizeFirstLetter(entry.field.join('/'))}
            </Typography>
          </Box>
          <Typography variant="body2">{capitalizeFirstLetter(entry.message)}</Typography>
        </Box>
      ))}
    </div>,
  );
};
