import { ThemeOptions } from '@material-ui/core';

/**
 * TypeScript's module augmentation.
 */
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    surface: Palette['primary'];
  }
  interface PaletteOptions {
    surface: PaletteOptions['primary'];
  }
}

/**
 * Theme configuration variables.
 */
export const APP_THEME_OPTIONS: ThemeOptions = {
  palette: {
    primary: { main: '#0644d7' },
    secondary: { main: '#8b949c' },
    surface: { main: '#202123' },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: { fontSize: 72, fontWeight: 500 },
    h2: { fontSize: 60, fontWeight: 500 },
    h3: { fontSize: 48, fontWeight: 500 },
    h4: { fontSize: 24, fontWeight: 600 },
    h5: { fontSize: 21, fontWeight: 600 },
    h6: { fontSize: 18, fontWeight: 600 },
  },
};

/**
 * UI configuration variables.
 */
export const APP_UI = {
  adminDrawerWidth: 240,
};
