import { Link, Route } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import { useIntegrationsQuery } from 'graphql/generated';
import { Routes } from 'enums';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 345,
  },
}));

const IntegrationsList = () => {
  const styles = useStyles();

  const { data, loading, error } = useIntegrationsQuery();

  if (loading) return null;
  if (!data) return null;

  return (
    <div className={styles.root}>
      <Grid container spacing={2}>
        {data.integrations.map((integration) => (
          <Card key={integration.id} className={styles.root}>
            <CardHeader
              avatar={<Avatar>{integration.name}</Avatar>}
              title={integration.name}
              subheader={integration.createdAt}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {integration.description}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
        {!data.integrations.length && (
          <>
            <Typography>There are no integrations to show.</Typography>
            <Link to={Routes.IntegrationsAdd}>
              <Button disableElevation variant="contained" color="primary">
                Set up integration
              </Button>
            </Link>
          </>
        )}
      </Grid>
    </div>
  );
};

export default IntegrationsList;
