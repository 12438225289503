import ReactDOM from 'react-dom';
import DateFnsUtils from '@date-io/date-fns';
import { ApolloProvider } from '@apollo/client/react';
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { client } from './graphql/client';
import { Routes } from './enums';
import { APP_THEME_OPTIONS } from './config';
import { PrivateRoute } from './components';
import { IntegrationsRoot, Page404, TenantUserLogin } from './layouts';
import reportWebVitals from './reportWebVitals';
import './index.css';

ReactDOM.render(
  <>
    <CssBaseline />
    <ApolloProvider client={client}>
      <ThemeProvider theme={createMuiTheme(APP_THEME_OPTIONS)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ToastContainer position="bottom-left" limit={3} />
          <BrowserRouter>
            <Switch>
              <Route path={Routes.PageNotFound} render={Page404} />
              <Route path={Routes.Login} component={TenantUserLogin} />
              <PrivateRoute path={Routes.Integrations} component={IntegrationsRoot} />
              <Redirect exact from={Routes.Home} to={Routes.Integrations} />
              <Route path="*" render={Page404} />
            </Switch>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
