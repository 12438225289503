import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO-8601 encoded UTC date time string. `Example value: "2019-07-03T20:47:55Z"`. */
  DateTimeScalar: any;
};

export type ActionObject = {
  __typename?: 'ActionObject';
  name: Scalars['String'];
  scheduleTriggeredFlows: Array<FlowObject>;
};

export type CreateShopifyStorefrontAccessTokenInput = {
  /** The name of the user’s Shopify shop */
  shop: Scalars['String'];
  /** The authorization code provided in the Shopify redirect. */
  code: Scalars['String'];
};

export type CreateShopifyStorefrontAccessTokenPayload = {
  __typename?: 'CreateShopifyStorefrontAccessTokenPayload';
  id?: Maybe<Scalars['Int']>;
  accessToken?: Maybe<Scalars['String']>;
  accessScope?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type CreateTenantPayload = {
  __typename?: 'CreateTenantPayload';
  id: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};


export type ExchangeShopifyAccessTokenInput = {
  shop: Scalars['String'];
  code: Scalars['String'];
};

export type ExchangeShopifyAccessTokenPayload = {
  __typename?: 'ExchangeShopifyAccessTokenPayload';
  accessToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type FlowConditionCreateInput = {
  fieldId: Scalars['String'];
  index: Scalars['Int'];
  operator: FlowOperatorType;
  value: Scalars['String'];
};

export type FlowConditionObject = {
  __typename?: 'FlowConditionObject';
  field: Scalars['String'];
  index: Scalars['Int'];
  operator: FlowOperatorType;
  value: Scalars['String'];
  flows: Array<FlowObject>;
};

/** Enumerates the types of used to define a conjunction types. */
export enum FlowConjunction {
  And = 'AND',
  Or = 'OR'
}

export type FlowCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  conditions: Array<FlowConditionCreateInput>;
  conjunction: FlowConjunction;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<FlowFrequency>;
  targetObjectId: Scalars['String'];
  name: Scalars['String'];
  startDateTime: Scalars['DateTimeScalar'];
  endDateTime?: Maybe<Scalars['DateTimeScalar']>;
  trigger?: Maybe<FlowTrigger>;
};

export type FlowCreatePayload = {
  __typename?: 'FlowCreatePayload';
  flow: FlowObject;
  userErrors: Array<UserError>;
};

/** Enumerates the types of frequencies used when scheduling a flow agent job. */
export enum FlowFrequency {
  Once = 'ONCE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type FlowObject = {
  __typename?: 'FlowObject';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  action?: Maybe<ActionObject>;
  conditions: Array<FlowConditionObject>;
  conjunction: FlowConjunction;
  description?: Maybe<Scalars['String']>;
  frequency: FlowFrequency;
  field: Scalars['String'];
  targetObject: IntegrationTargetObject;
  name: Scalars['String'];
  startDateTime: Scalars['DateTimeScalar'];
  endDateTime?: Maybe<Scalars['DateTimeScalar']>;
  tasks: Array<TaskObject>;
  trigger: FlowTrigger;
  tenantId: Scalars['String'];
};

/** Logical and comparison operators */
export enum FlowOperatorType {
  Equals = 'EQUALS',
  DoesNotEqual = 'DOES_NOT_EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  Contains = 'CONTAINS'
}

export enum FlowTrigger {
  Schedule = 'SCHEDULE',
  Event = 'EVENT'
}

export type IntegrationObject = {
  __typename?: 'IntegrationObject';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTimeScalar'];
  updatedAt: Scalars['DateTimeScalar'];
};

export type IntegrationTargetObject = {
  __typename?: 'IntegrationTargetObject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Authentication to the API via HTTP Basic Auth. */
  tenantUserLogin: TenantUserLoginPayload;
  /** Creates a fresh access token. */
  refreshToken: RefreshTokenPayload;
  /** Creates a new tenant. */
  tenantSignUp: SignUpTenantPayloadDto;
  /** Creates a tenant user. */
  tenantUserSignUp: SignUpTenantUserPayload;
  /** Exchanges the Shopify authorization code for a permanent access token. */
  exchangeShopifyAccessToken: ExchangeShopifyAccessTokenPayload;
  /** Creates a new Shopify storefront access token. */
  createShopifyStorefrontAccessToken: CreateShopifyStorefrontAccessTokenPayload;
  tenantCreate: CreateTenantPayload;
  flowCreate: FlowCreatePayload;
};


export type MutationTenantUserLoginArgs = {
  input: TenantUserLoginInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationTenantSignUpArgs = {
  input: SignUpTenantInput;
};


export type MutationTenantUserSignUpArgs = {
  input: SignUpTenantUserInputDto;
};


export type MutationExchangeShopifyAccessTokenArgs = {
  input: ExchangeShopifyAccessTokenInput;
};


export type MutationCreateShopifyStorefrontAccessTokenArgs = {
  input: CreateShopifyStorefrontAccessTokenInput;
};


export type MutationTenantCreateArgs = {
  tenant: TenantInput;
};


export type MutationFlowCreateArgs = {
  input: FlowCreateInput;
};

export type Query = {
  __typename?: 'Query';
  user: TenantUserObject;
  users: Array<TenantUserObject>;
  flows: Array<FlowObject>;
  integrations: Array<IntegrationObject>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RefreshTokenObject = {
  __typename?: 'RefreshTokenObject';
  id: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  isRevoked: Scalars['Boolean'];
  expires: Scalars['String'];
  tenantUser: TenantUserObject;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  user: TenantUserObject;
  accessToken: Scalars['String'];
};

export type SignUpTenantInput = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SignUpTenantPayloadDto = {
  __typename?: 'SignUpTenantPayloadDto';
  /** The created user. */
  user?: Maybe<User>;
  /** List of errors that occurred executing the mutation. */
  userErrors: Array<UserError>;
};

export type SignUpTenantUserInputDto = {
  company: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  givenName: Scalars['String'];
  familyName: Scalars['String'];
};

export type SignUpTenantUserPayload = {
  __typename?: 'SignUpTenantUserPayload';
  user: TenantUserObject;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type TaskObject = {
  __typename?: 'TaskObject';
  flow: FlowObject;
};

export type TenantInput = {
  name: Scalars['String'];
};

export type TenantObject = {
  __typename?: 'TenantObject';
  id: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  tier: Scalars['String'];
  tenantUsers?: Maybe<Array<TenantUserObject>>;
  users: Array<TenantUserObject>;
};

export type TenantUserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type TenantUserLoginPayload = {
  __typename?: 'TenantUserLoginPayload';
  user?: Maybe<TenantUserObject>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  /** List of errors that occurred executing the mutation. */
  userErrors: Array<UserError>;
};

export type TenantUserObject = {
  __typename?: 'TenantUserObject';
  id: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  owner: TenantObject;
  refreshTokens?: Maybe<Array<RefreshTokenObject>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  tenantId: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** Represents an error in the input of a mutation.   */
export type UserError = {
  __typename?: 'UserError';
  /** Path to the input field which caused the error. */
  field: Array<Scalars['String']>;
  /** The error message. */
  message: Scalars['String'];
};

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = (
  { __typename?: 'Query' }
  & { integrations: Array<(
    { __typename?: 'IntegrationObject' }
    & Pick<IntegrationObject, 'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'>
  )> }
);

export type TenantUserLoginMutationVariables = Exact<{
  input: TenantUserLoginInput;
}>;


export type TenantUserLoginMutation = (
  { __typename?: 'Mutation' }
  & { tenantUserLogin: (
    { __typename?: 'TenantUserLoginPayload' }
    & Pick<TenantUserLoginPayload, 'accessToken'>
    & { userErrors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'field' | 'message'>
    )> }
  ) }
);


export const IntegrationsDocument = gql`
    query Integrations {
  integrations {
    id
    name
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
      }
export function useIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
        }
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<IntegrationsQuery, IntegrationsQueryVariables>;
export const TenantUserLoginDocument = gql`
    mutation TenantUserLogin($input: TenantUserLoginInput!) {
  tenantUserLogin(input: $input) {
    accessToken
    userErrors {
      field
      message
    }
  }
}
    `;
export type TenantUserLoginMutationFn = Apollo.MutationFunction<TenantUserLoginMutation, TenantUserLoginMutationVariables>;

/**
 * __useTenantUserLoginMutation__
 *
 * To run a mutation, you first call `useTenantUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantUserLoginMutation, { data, loading, error }] = useTenantUserLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTenantUserLoginMutation(baseOptions?: Apollo.MutationHookOptions<TenantUserLoginMutation, TenantUserLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantUserLoginMutation, TenantUserLoginMutationVariables>(TenantUserLoginDocument, options);
      }
export type TenantUserLoginMutationHookResult = ReturnType<typeof useTenantUserLoginMutation>;
export type TenantUserLoginMutationResult = Apollo.MutationResult<TenantUserLoginMutation>;
export type TenantUserLoginMutationOptions = Apollo.BaseMutationOptions<TenantUserLoginMutation, TenantUserLoginMutationVariables>;