export enum FlowOperatorTypes {
  /**
   * `==` Equals
   */
  EQUALS = 'EQUALS',
  /**
   * `!=` Does not equal
   */
  DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
  /**
   * `>` Does not equal
   */
  GREATER_THAN = 'GREATER_THAN',
  /**
   * `<` Less than
   */
  LESS_THAN = 'LESS_THAN',
  /**
   * `>=` Greater than or equal to
   */
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  /**
   * `<=` Less than or equal to
   */
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  /**
   * Contains checks for the presence of a substring in a string.
   * Contains can also check for the presence of a string in an array of strings.
   * Contains can only search strings. You cannot use it to check for an object in an array of objects.
   */
   CONTAINS = 'CONTAINS',
}
