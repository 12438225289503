import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Routes } from 'enums';
import { useAuth } from 'providers/auth';

/**
 * A wrapper for <Route> that redirects to the login or tenant page
 * that don't let request proceed if user not yet authenticated.
 */
export const PrivateRoute = (props: RouteProps) => {
  const [isLoggedIn] = useAuth();

  return isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: Routes.Login,
        state: { from: props.location },
      }}
    />
  );
};
